import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './routes/root'
import ErrorPage from './errorPage'
import About from './routes/about'
import Writing from  './routes/writing'
import Post from './components/writing/Post'
import LandingPage from './components/LandingPage';
import Passarelleo from './routes/passarelleo';
import Imperialism from './routes/imperialism';
import Messages from './routes/messages';
import Fraternity from './routes/fraternity/fraternity';
import FullTree from './routes/fraternity/fulltree'
import NavBar from './components/navigation/NavBar';
import FraternityLogin from './routes/fraternity/fraternitylogin';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import TreePage from './unused components/tree/treePage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/about',
        element: <About />
      },
      {
        path: '/',
        element: <LandingPage />
      },
      {
        path: '/writing',
        element: <Writing />,
      },
      {
        path: '/dev/post/:postID',
        element: <Post />,
      },
      {
        path: '/fraternity',
        element: <Fraternity />,
      },
      {
        path: '/fraternity/login',
        element: <FraternityLogin />,
      },
      {
        path: '/passarelleo',
        element: <Passarelleo />,
      },
      {
        path: '/imperialism',
        element: <Imperialism />
      },
      {
        path: '/messages',
        element: <Messages />
      }
    ]
  }
])

root.render(
    <RouterProvider router={router} />
);