import { useEffect, useRef, useState, forwardRef, useLayoutEffect } from 'react';
import { instance } from '@viz-js/viz';

const scrollName = 'family-treescrollgen3'

export default function FullTree({targetScroll} : {targetScroll: string}) {
    const [graphData, setGraphData] = useState<string | null>(null)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const svgRef = useRef<SVGSVGElement | null>(null)

    const fetchGraph = async () => {
        if (graphData) return
        try {
            const dotfilePath = `https://server.duplantis.org/fraternity/tree`
            let response = await fetch(dotfilePath, { headers: { 'jwt-token': localStorage.getItem('jwt-token') || '' } })
            if (!response.ok) { console.error(`Failed to fetch graph data: ${response.statusText}`) }
            
            return await response.text()
        } catch(e) {console.error(e)}
    }

    const graphvizRender = async (graphData: string) => {
        //console.log(`Attempting to render: ${graphData}`)
        const viz = await instance()
        const svgElement = await viz.renderSVGElement(graphData)
        if (svgRef.current) {
            svgRef.current.innerHTML = svgElement.innerHTML
            const bbox = svgRef.current.getBBox()
            svgRef.current.setAttribute('viewBox', `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`)
            setIsLoaded(true)
        }
    }

    const renderGraph = async (graphData: string) => {
        if (!svgRef.current) return
        try {
            console.log("Rendering graph...")
            svgRef.current.innerHTML = ''; // Clear previous content
            const graph = targetScroll !== '' ? await filterGraph(graphData, targetScroll) : graphData
            svgRef.current.innerHTML = ''; // Clear previous content
            await graphvizRender(graph)
            // asda
            console.log("Completed rendering graph.")
            
        } catch (e) {
            console.error("ERROR during renderGraph():", e)
        }
    }

    const graph = async () => {
        if (svgRef) {
            const graphResponse = await fetchGraph()
            setGraphData(graphResponse ?? null)
            if (graphResponse) {
                //console.log(`Graph data: ${graphResponse}`
                renderGraph(graphResponse)
            } else { console.error('Failed to fetch graph data.') }
        }
    }//

    useEffect(() => {
        if (svgRef.current) {
            graph()
        }
    }, [targetScroll])

    return (<>
            <svg ref={svgRef} className={`transition-opacity duration-1000 ease-in-out ${isLoaded ? 'opacity-100' : 'opacity-0'}`}></svg>
    </>);
}

function filterGraph(graph: any, targetScroll: string) {

    let graphRows = removeDuplicates(graph)
    // create array with targetScroll
    let upperLineageScrolls = [targetScroll]
    let lowerLineageScrolls = [targetScroll]
    let newGraphRows = [`digraph "${scrollName}" {`, `node [charset="UTF-16" fillcolor=white fixedsize=true fontname="Times New Roman" height=1 shape=box style="rounded, filled, solid" width=2]`]
    
    
    

    // Iterate through the cleaned dot file row by row downwards
    console.log(`--- starting with lower ${lowerLineageScrolls} ---`)
    for (let i = 0; i < graphRows.length; i++) {
        // for each row, check it against each recorded lineage scroll
        let newLineageScroll: string = ''
        let knownLineageScroll: string = ''
        let newLineageScrollFound: boolean = false
        for (let j = 0; j < lowerLineageScrolls.length; j++) {
            //console.log(lineageScrolls)
            if (graphRows[i].includes(lowerLineageScrolls[j])) {
                newLineageScrollFound = true
                console.log(`${graphRows[i].trim()} contains ${lowerLineageScrolls[j]}!`)
                knownLineageScroll = lowerLineageScrolls[j]
                // check if the row is a edge
                if (graphRows[i].includes('->')) {
                    console.log('its an edge!')
                    // Adding other scroll to lineage list
                    if (graphRows[i].trim().startsWith(knownLineageScroll)) {
                        console.log(`"${graphRows[i].trim()} starts with ${knownLineageScroll}"`)
                        newLineageScroll = graphRows[i].slice(knownLineageScroll.length + 5, -1)
                    } else if (graphRows[i].trim().endsWith(knownLineageScroll)) { 
                        newLineageScroll = graphRows[i].slice(1, graphRows[i].length - (knownLineageScroll.length + 5))
                        console.log(`"${graphRows[i].trim()} ends with ${knownLineageScroll}"`)
                    }
                }
            }
        }
        if (newLineageScrollFound && newLineageScroll !== '' && Number(targetScroll) < Number(newLineageScroll)) {lowerLineageScrolls.push(newLineageScroll) }
    }

    // Moving upwards
    console.log(`--- starting with upper ${upperLineageScrolls} ---`)
    for (let i = graphRows.length - 1; i > 0; i--) {
        // for each row, check it against each recorded lineage scroll
        let newLineageScroll: string = ''
        let knownLineageScroll: string = ''
        let newLineageScrollFound: boolean = false
        for (let j = 0; j < upperLineageScrolls.length; j++) {
            //console.log(lineageScrolls)
            if (graphRows[i].includes(upperLineageScrolls[j])) {
                newLineageScrollFound = true
                console.log(`${graphRows[i].trim()} contains ${upperLineageScrolls[j]}!`)
                knownLineageScroll = upperLineageScrolls[j]
                // check if the row is a edge
                if (graphRows[i].includes('->')) {
                    console.log('its an edge!')
                    // Adding other scroll to lineage list
                    if (graphRows[i].trim().startsWith(knownLineageScroll)) {
                        //console.log(`"${graphRows[i].trim()} starts with ${knownLineageScroll}"`)
                        //newLineageScroll = graphRows[i].slice(knownLineageScroll.length + 5, -1)
                        break
                    } else if (graphRows[i].trim().endsWith(knownLineageScroll)) { 
                        newLineageScroll = graphRows[i].slice(1, graphRows[i].length - (knownLineageScroll.length + 5))
                        console.log(`"${graphRows[i].trim()} ends with ${knownLineageScroll}"`)
                    }
                }
            }
        }
        if (newLineageScrollFound && newLineageScroll !== '') {upperLineageScrolls.push(newLineageScroll) }
    }
    

    console.log('lower: ' + lowerLineageScrolls)
    console.log('upper: ' + upperLineageScrolls)
    const lineageScrolls = lowerLineageScrolls.concat(upperLineageScrolls.slice(1))
    console.log(lineageScrolls)

    // for each row
    for (let i = 0; i < graphRows.length; i++) {
        let trimmedRow = graphRows[i].trim()
        for (let j = 0; j < lineageScrolls.length; j++) {
            if (graphRows[i].includes(' ' + lineageScrolls[j]) || graphRows[i].includes(lineageScrolls[j] + ' ')) {
                let foundLineageScroll = lineageScrolls[j]
                console.log(`relevant row found!: ${trimmedRow}, found scroll: ${lineageScrolls[j]}`)
                if (graphRows[i].includes('->')) {
                    // get other scroll
                    let otherScroll
                    if (graphRows[i].trim().startsWith(foundLineageScroll)) {
                        otherScroll = graphRows[i].slice(foundLineageScroll.length + 5, -1)
                    } else if (graphRows[i].trim().endsWith(foundLineageScroll)) { 
                        otherScroll = graphRows[i].slice(1, graphRows[i].length - (foundLineageScroll.length + 5))
                    }
                    console.log(`edge found! checking against ${otherScroll}`)
                    if (lineageScrolls.includes(otherScroll)){
                        console.log('edge is lineage only')
                        console.log(graphRows[i])
                        newGraphRows.push(graphRows[i])
                    } else { break }
                }  else {
                    newGraphRows.push(graphRows[i])
                }
                
            }
            
        }
    }

        // if edge (contains "->") contains target scroll: add edge to new dot
        // add new number to target array, iterate through array
        // add all labels of numbers in array, sort by scroll ascending

    // join together
    newGraphRows.push(`}`)
    let newGraph = newGraphRows.join('\n')
    
    //console.log(newGraph)
    return removeDuplicates(newGraph).join('\n')
    
}

// removing duplicates
function removeDuplicates(graph: any) {
    let graphRows = graph.split('\n')
    for (let i = 0; i < graphRows.length; i++) {
        if (graphRows[i+1] == graphRows[i]) {
            graphRows[i] = ''
        }
    }
    return graphRows.filter((row: string) => row !== '')
}